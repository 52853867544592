import { KVNamespace } from '@cloudflare/workers-types';

export default defineNuxtPlugin((test) => {
    console.log('test', test);
});

// export default defineEventHandler(async (event) => {
//     const { context } = event;
//     const MY_KV: KVNamespace = context?.cloudflare?.env?.KV_TEST;
//     const MY_KV_value = !MY_KV ? 'null' : await MY_KV.get('TEST');
//     const MY_KV_value2 = (await !MY_KV) ? 'null' : MY_KV.get('TEST1234');
//     console.log('client middleware');
//     //const app = useNuxtApp();
//     //const appState = app.$appState();

//     // const state = useAppState();
//     // state.value.TEST = MY_KV_value;
//     // state.value.TEST1234 = MY_KV_value2;

//     // return {
//     //     MY_KV_Nullcheck: !!MY_KV,
//     //     MY_KV_serialised: JSON.stringify(MY_KV),
//     //     MY_KV_value,
//     //     MY_KV_value2
//     // };
// });
